<template>
  <kpi-component />
</template>

<script>
import store from '@/store'
const KpiComponent = () => import('@/components/Kpi')

export default {
  name: 'KpiView',
  components: {
    KpiComponent
  },
  beforeRouteEnter (to, from, next) {
    if (store.state.User.userData.role === 'OPERATOR' || store.state.User.userData.role === 'MAINTAINER') {
      next('/dashboard')
    } else {
      next()
    }
  }
}
</script>
